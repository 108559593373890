
// https://dice-antrepriz.atlassian.net/browse/CTOOO-142

import React, { useRef, useState } from "react";
import { Button, Input, NavDivider, Navigation, TOAST } from "retro/index";
import { post } from "App/Network/Axios";

export default function index() {
  const TRANSID = useRef();
  const CN = useRef();
  const CRN = useRef();
  const GST=useRef()
  const REMARKS = useRef();
  const [loading, setLoading] = useState(false);


  return (
    <div>
      <Navigation backUrl={"back"} title="Cancel and Switch to other entity
" />
      <NavDivider />
      <Input
        ref={TRANSID}
        type="text"
        label="Transaction Id"
        placeholder="Transaction Id"
      />
      <Input
				ref={GST}
				type="text"
				label="New Gst Number"
				placeholder="New Gst Number"
			/>
      <Input
        ref={CN}
        type="text"
        defaultValue="CRFN"
        label="Credit Note Prefix"
        placeholder="Credit Note Prefix"
      />
      <Input
        ref={CRN}
        type="text"
        defaultValue="CRFNR"
        label="Cancellation Credit Note Prefix"
        placeholder="Cancellation Credit Note Prefix for Old Service Charge"
      />
      <Input ref={REMARKS} type="text" label="Remarks" placeholder="Remarks" />
      <Button
        onClick={() => {
          let transId = TRANSID.current.value;
          let cn = CN.current.value;
          let remarks = REMARKS.current.value;
          let crn = CRN.current.value;
          let gstin = GST.current.value;

          if (!transId) {
            TOAST.error("Enter a valid trans id");
            return;
          }
          if (!cn) {
            TOAST.error("Enter a valid credit Note");
            return;
          }
          if (!crn) {
            TOAST.error("Enter a valid Cancellation credit Note");
            return;
          }
          if (!gstin) {
            TOAST.error("Enter a valid GST number");
            return;
          }
          if (!remarks) {
            TOAST.error("Enter a valid Remarks");
            return;
          }
          setLoading(true);
          let data = {
            remarks,
            crn,
            cn,
            gstin
          }


          post(
            `/meta/${transId}/agency/cancellation/entitySwitch`,
            data,
            (err, res) => {

              setLoading(false);
              if (res) {
                TOAST.success("Regeneration done successfully");
                TRANSID.current.value = "";
                REMARKS.current.value = "";
              } else {
                TOAST.handleError(err);
              }
            }
          );
        }}
        className="btn-black btn-primary"
        margin="pd2 mt4"
        loading={loading}
      >
        Regenerate
      </Button>
    </div>
  );
}
