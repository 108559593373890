import React, {useEffect, useRef, useState} from 'react';
import {
	Attachment,
	Button, FileInput,
	Input,
	NavDivider,
	Navigation,
	SelectInput,
	TOAST,
} from 'retro/index';
import Moment from 'moment';
import {get, post} from 'App/Network/Axios';
import {AddTravellerModal, PrefferedGST} from '../ImportHotel';
import {useHistory} from "react-router-dom";

export default function index() {
	const [formData, setFormData] = useState({
		prefferedGst: undefined,
		prefferedGstName: undefined,
		clientCode: undefined,
		clientId: undefined,
		email: undefined,
		allGsts: [],
	});
	const GSTIN = useRef();
	const EMAIL = useRef();
	const PG = useRef();
	const ACCOUNT = useRef();

	const [file, setFile] = useState(undefined)
	const FILE = useRef()
	const COUNTRY = useRef();
	const PASSPORT = useRef();
	const VENDORNAME = useRef();
	const VENDORINVOICE = useRef();
	const VENDORAMOUNT = useRef();
	const HANDLING = useRef();
	const VISA_START = useRef();
	const REQUEST_ID = useRef();
	const VISA_TYPE = useRef();
	const VISA_END = useRef();
	const VISA_CONFIRMATION = useRef();
	const REMARKS = useRef();
	const TRIPID = useRef();
	const HISTORY = useHistory();
	const [loading, setLoading] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [accountType, setAccountType] = useState(undefined);
	const [travellers,setTravellers] = useState([])
	// set dropdowns as per the cleint
	useEffect(() => {
		setAccounts([]);
		if (formData.clientCode) {
			// get the list of accounts for the client
			get(`/accounts/${formData.clientId}`, (e, r) => {
				if (r) {
					setAccounts(r.items);
					setAccountType(undefined);
				}
			});
		}
	}, [formData.clientCode]);

	const importBooking = () => {
		let email = EMAIL.current.value;
		if (!email) {
			TOAST.error('Enter a valid email address');
			return;
		}
		if (!file) {
			TOAST.error('Kindly select a attachment');
			return;
		}
		let country = COUNTRY.current.value;
		if (!country) {
			TOAST.error('Enter a valid country');
			return;
		}
		let passport = PASSPORT.current.value;
		if (!passport) {
			TOAST.error('Enter a valid passport');
			return;
		}

		let vendorName = VENDORNAME.current.value;
		if (!vendorName) {
			TOAST.error('Enter a valid vendorName');
			return;
		}

		let vendorInvoice = VENDORINVOICE.current.value;
		if (!vendorInvoice) {
			TOAST.error('Enter a valid vendorInvoice');
			return;
		}

		let vendorAmount = VENDORAMOUNT.current.value;
		if (!vendorAmount) {
			TOAST.error('Enter a valid vendorAmount');
			return;
		}

		let start = VISA_START.current.value;
		if (!vendorName) {
			TOAST.error('Enter a valid start date');
			return;
		}

		let end = VISA_END.current.value;
		if (!end) {
			TOAST.error('Enter a valid end date');
			return;
		}

		let handling = HANDLING.current.value;
		if (!handling) {
			TOAST.error('Enter a valid handling');
			return;
		}

		let confirmation = VISA_CONFIRMATION.current.value;
		if (!confirmation) {
			TOAST.error('Enter a valid confirmation');
			return;
		}
		let type = VISA_TYPE.current.value;
		if (!type) {
			TOAST.error('Enter a valid type');
			return;
		}
		let remarks = REMARKS.current.value;
		if (!remarks) {
			TOAST.error('Enter a valid remarks');
			return;
		}
		if(!travellers || !travellers.length){
			TOAST.error(("Enter traveller details"));
			return;
		}
		setLoading(true);
		let data = {
			email,
			file,
			ticket: {
				file,
				country,
				passport,
				vendorName,
				vendorInvoice,
				requestId : REQUEST_ID.current.value,
				vendorAmount,
				type,
				start:Moment(start,"YYYY-MM-DD").format("DD-MM-YYYY"),
				end:Moment(end,"YYYY-MM-DD").format("DD-MM-YYYY"),
				confirmation,
				handling,
				remarks,
				traveller:travellers[0],
			},
			account: ACCOUNT.current.value,
			pgId: PG.current?.value,
			client: formData.clientCode,
			gstin:GSTIN.current.value,
			code: formData.clientCode,
			tripId: TRIPID?.current?.value,
		};
		post(`/meta/visa`, data, (e, r) => {
			if (e) {
				TOAST.handleError(e);
			} else {
				TOAST.success('Visa imported successfully!');
				EMAIL.current.value = '';
				if(r?.refId){
					HISTORY.push(`/app/apps/meta/transaction/${r.refId}`)
				}
				// https://dice-antrepriz.atlassian.net/browse/CTOOO-71
				// page was not getting routed to next page
				if(r?.transId){
					HISTORY.push(`/app/apps/meta/transaction/${r.transId}`)
				}
			}
			setLoading(false);
		});
	};
	return (
		<div>
			<Navigation backUrl={'back'} title="Import Visa"/>
			<NavDivider/>
			{!formData.prefferedGst ? (
				<PrefferedGST data={formData} setData={setFormData} category="visa"/>
			) : (
				<div className="mt4 pd4 border rounded-md">
					<SelectInput
						placeholder="Select"
						hidePlaceholder={false}
						options={[
							...accounts.map((item) => {
								return {
									label: `${item.title} (INR ${item.balance})`,
									value: item.accountId,
								};
							}),
							{label: 'RAZORPAY', value: 'RAZORPAY'},
						]}
						onChange={setAccountType}
						ref={ACCOUNT}
						label="Select Deposit Account"
						className="flex-1"
					/>
					<SelectInput
						placeholder="Select"
						hidePlaceholder={false}
						options={[
							{
								label: `${formData.prefferedGst} - ${formData.prefferedGstName} (Preffered GST)`,
								value: formData.prefferedGst,
							},
							...formData.allGsts.map((gst) => {
								return {
									label: `${gst.gstin} - ${gst.name}`,
									value: gst.gstin,
								};
							}),
						]}
						defaultValue={formData.prefferedGst}
						ref={GSTIN}
						label="Select the GSTIN"
						className="flex-1 mt2"
					/>
					{/* https://dice-antrepriz.atlassian.net/browse/CTOOO-71 */}
					<div className="flex horizontally center-vertically mt2">
						<Input 
						type='text' 
						ref={TRIPID} 
						label="Enter Trip Id (Optional)"
						placeholder="Enter trip id"
						className="flex-1"
						/>
					</div>
					{accountType === 'RAZORPAY' && (
						<Input
							ref={PG}
							type="text"
							label="Enter Pg Id"
							placeholder="Enter Pg Id"
							className="mb2"
						/>
					)}
					<Input
						ref={EMAIL}
						type="text"
						label="Traveller Email Address"
						placeholder="Traveller Address"
						className="flex-1 mt2"
						defaultValue={formData.email}
					/>

					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={COUNTRY}
							type="text"
							label="Visa Country"
							placeholder="Visa Country"
							className="flex-1 mr2"
						/>
						<Input
							ref={PASSPORT}
							type="text"
							label="Traveller Passport Number"
							placeholder="Traveller Passport Number"
							className="flex-1 ml2"
						/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={VENDORNAME}
							type="text"
							label="Vendor Name"
							placeholder="Vendor Name"
							className="flex-1 mr2"
						/>
						<Input
							ref={VENDORINVOICE}
							type="text"
							label="Vendor Invoice"
							placeholder="Vendor Invoice"
							className="flex-1 ml2"
						/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={VENDORAMOUNT}
							type="number"
							label="Vendor Amount"
							placeholder="Vendor Amount"
							className="flex-1 mr2"
						/>
						<Input
							ref={VISA_CONFIRMATION}
							type="text"
							label="Visa Confirmation Number"
							placeholder="Visa Confirmation Number"
							className="flex-1 ml2"
						/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={VISA_START}
							type="date"
							label="Visa Start Date"
							placeholder="Vendor Amount"
							className="flex-1 mr2"
						/>
						<Input
							ref={VISA_END}
							type="date"
							label="Visa End Date"
							placeholder="Visa Confirmation Number"
							className="flex-1 ml2"
						/>
					</div>
					<div className="flex horizontally center-vertically mt2">
						<Input
							ref={VISA_TYPE}
							type="text"
							label="Visa Type"
							placeholder="Visa Type"
							className="flex-1 mr2"
						/>
						<Input
							ref={HANDLING}
							type="number"
							label="Handling Charges"
							placeholder="Handling Charges"
							className="flex-1 ml2"
						/>
					</div>
					<Input
						ref={REMARKS}
						type="text"
						label="Remarks"
						placeholder="Enter Remarks"
						className="flex-1 mt2"
					/>
					<Input
						ref={REQUEST_ID}
						type="text"
						label="Request Id"
						placeholder="Enter Request Id"
						className="flex-1 mt2"
					/>
					<div className='mt2'>
						<label className='text-input-label'>Add Attachments</label>
					</div>
					
					<div className='flex mb2'>
						<FileInput
							updateFiles={(url) => {
								setFile(url);
							}}
							ref={FILE}
						/>
						<Attachment url={file} />
					</div>
					{/* https://dice-antrepriz.atlassian.net/browse/CTOOO-417 */}
					<AddTravellerModal
						travellers={travellers}
						setTravellers={setTravellers}
						hideSeatNumber={true}
						allowSingleTravllerOnly={true}
					/>
					<Button
						onClick={() => setFormData({})}
						className="btn-black btn-primary mr4"
						margin="pd2 mt4"
					>
						<i className="fa-solid fa-arrow-left mr1"/>
						Previous
					</Button>
					<Button
						onClick={importBooking}
						loading={loading}
						className="btn-black btn-primary mr4"
						margin="pd2 mt4"
					>
						Import Visa
					</Button>
				</div>
			)}
		</div>
	);
}
