
// https://dice-antrepriz.atlassian.net/browse/CTOOO-142

import React, { useRef, useState } from "react";
import { Button, Input, NavDivider, Navigation, TOAST } from "retro/index";
import { post } from "App/Network/Axios";

export default function index() {
  const TRANSID = useRef();
  const BASEFARE = useRef();
  const OTHERCHARGES = useRef();
  const GST=useRef()
  const PNR=useRef()
  const REMARKS = useRef();
  const [loading, setLoading] = useState(false);


  return (
    <div>
      <Navigation backUrl={"back"} title="Update Fare Breakup" />
      <NavDivider />
      <Input
        ref={TRANSID}
        type="text"
        label="Transaction Id"
        placeholder="Transaction Id"
      />
         <Input
		ref={PNR}
		type="text"
		label="PNR"
		placeholder="PNR"
	  />
      <Input
		ref={GST}
		type="number"
		label="Gst"
		placeholder="Gst"
	  />
      <Input
        ref={BASEFARE}
        type="number"
        label="Base Fare"
        placeholder="Base Fare"
      />
      <Input
        ref={OTHERCHARGES}
        type="number"
        label="Other charges"
        placeholder="Other Charges"
      />
      <Input ref={REMARKS} type="text" label="Remarks" placeholder="Remarks" />
      <Button
        onClick={() => {
          let transId = TRANSID.current.value;
          let baseFare = Number(BASEFARE.current.value);
          let remarks = REMARKS.current.value;
          let otherCharges = Number(OTHERCHARGES.current.value);
          let gst = Number(GST.current.value);
          let pnr = PNR.current.value;


          if (!transId) {
            TOAST.error("Enter a valid trans id");
            return;
          }
          if (!baseFare) {
            TOAST.error("Enter a valid Base Fare");
            return;
          }
          if (!pnr) {
            TOAST.error("Enter a valid pnr");
            return;
          }

          setLoading(true);
          let data = {
            remarks,
            otherCharges,
            baseFare,
            gst,
            pnr
          }

          post(
            `/meta/${transId}/flight/agency/fareBreakup`,
            data,
            (err, res) => {

              setLoading(false);
              if (res) {
                TOAST.success("Regeneration done successfully");
                TRANSID.current.value = "";
                REMARKS.current.value = "";
              } else {
                TOAST.handleError(err);
              }
            }
          );
        }}
        className="btn-black btn-primary"
        margin="pd2 mt4"
        loading={loading}
      >
        Regenerate
      </Button>
    </div>
  );
}
