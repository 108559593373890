import React, {useEffect, useState} from 'react'
import {
	Button,
	DateRange,
	Loader,
	NavDivider,
	Navigation,
	ScrollableDynamicTable,
	Seperator,
	TableViewSwitch
} from "../../../../../../retro";
import Moment from 'moment-timezone'
import {get} from "../../../../../Network/Axios";
import {useHistory} from "react-router-dom";
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import { DownloadCsv } from 'retro/Utils';

export default function index() {
	const [Dates, setDates] = useState({
		page:0
	});
	const [VIEW, SETVIEW] = useState("list")
	const HISTORY = useHistory();
	const [loading,setLoading] = useState(false)
	const [Data, SetData] = useState({
		total: 0,
		currentPage: 0,
		totalPages: 0,
		items: []
	})
	useEffect(()=>{
		if(Dates){
			get(`/meta/flight/refunding`,(e,r)=>{
				if(r){
					SetData({
						...r,
						currentPage: Dates.page ? Dates.page : 0,
						totalPages: r.totalPages > 0 ? r.totalPages : Data.totalPages,
						total: r.total > 0 ? r.total : Data.total
					})
				} else {
					Alert.handleError(e)
				}
			},{
				offset:Dates.page
			})
		}
	},[Dates])
	if(loading){
		return (
			<div>
				<div style={{
					margin:'0 -4rem',
					padding:'2rem 4rem',
					borderBottom:'1px solid #dedede'
				}}>
					<div className="flex horizontally center-vertically">
						<div className="flex-1">
							<DateRange  defaultValue={{
								startDate:Moment().add(-31,'d').valueOf(),
								endDate : Moment().valueOf()
							}}  onChange={()=>{}} className='' label='Export from and to' type='range'/>
						</div>
					</div>
				</div>
				<div className="pd6">
					<Loader/>
				</div>
			</div>
		)
	}
	let items = [];

					{/* https://dice-antrepriz.atlassian.net/browse/CTOOO-536 */}

	Data.items.forEach(item=>{
		const { segments } = item.ticket;
		const departureDate = segments?.[0]?.departureTime
		  ? Moment(segments[0].departureTime)
		      .tz(
			segments[0].departure?.timezoneId === "IST"
			  ? "Asia/Kolkata"
			  : segments[0].departure?.timezoneId || "Asia/Kolkata",
		      )
		      .format("DD-MM-YYYY")
		  : "-";
		const departureTime = segments?.[0]?.departureTime
		  ? Moment(segments[0].departureTime)
		      .tz(
			segments[0].departure?.timezoneId === "IST"
			  ? "Asia/Kolkata"
			  : segments[0].departure?.timezoneId || "Asia/Kolkata",
		      )
		      .format("HH:mm")
		  : "-";
		const timezone = segments[0].departure?.timezoneId === "IST" ? "Asia/Kolkata" : segments[0].departure?.timezoneId || "Asia/Kolkata";

		items.push({
			content: item,
			render: [
				item.transId,item.provider,item.providerId||"-",item.client.code,item.client.name,item.paymentType
				,item.amount,departureDate, departureTime, timezone, {content:(<p className="fw-bold pointer" onClick={()=>{
						HISTORY.push(`/app/apps/meta/transaction/${item.transId}`)
					}}>View</p>)}

			].map(title=>{
				if(title.content){
					return {weight:2,children:title.content}
				}else{
					return {weight:2,title}
				}
			})
		})
	})
	return (
		<div style={{width:'100%'}}>
			<Navigation backUrl={''} title="Refund Queue"  description="Flight refund queue file appears below."/>
			{ <RBAC role={ROLES.REPORTS}>
            <Button
              onClick={() => {
                setLoading(true);
                get(`/meta/flight/refunding`, (e, r) => {
                  if (r) {
                    let data = [];
                    let headers = [
                      "Trans Id","Provider","Provider Id","Client Code","Client Name","Payment Type",
					"Amount", "Departure Date", "Departure Time", "Timezone", "Status"
                    ];
                    data.push(headers);
					
                    r.items.forEach((item) => {
			const { segments } = item.ticket;
			const departureDate = segments?.[0]?.departureTime
			  ? Moment(segments[0].departureTime)
			      .tz(
				segments[0].departure?.timezoneId === "IST"
				  ? "Asia/Kolkata"
				  : segments[0].departure?.timezoneId|| "Asia/Kolkata",
			      )
			      .format("DD-MM-YYYY")
			  : "-";
			const departureTime = segments?.[0]?.departureTime
			  ? Moment(segments[0].departureTime)
			      .tz(
				segments[0].departure?.timezoneId === "IST"
				  ? "Asia/Kolkata"
				  : segments[0].departure?.timezoneId,
			      )
			      .format("HH:mm")
			  : "-";
			const timezone = segments[0].departure?.timezoneId === "IST" ? "Asia/Kolkata" : segments[0].departure?.timezoneId || "Asia/Kolkata";

					  data.push([
						item.id,
						item.provider,
						item.providerId,
						item.client.code,
						item.client.name,
						item.paymentType,
						item.ticket.amount,
						departureDate,
						departureTime,
						timezone,
						item.status,
					  ]);
                    });
                    DownloadCsv(
                      `all_flight_cancellation_${Moment().format('DD_MM_YYYY')}.csv`,
                      data
                    );
                  }
                  setLoading(false);
                });
              }}
              style={{ zIndex: 11 }}
              loading={loading}
              className='btn absolute top-3 right-6 btn-primary'
            >
              <i className='fa fa-download mr1' />
              Download Data
            </Button>
          </RBAC> }

			<NavDivider/>
			<div>
				<ScrollableDynamicTable
					autoTrigger={false}
					tableStyle={{
						borderSpacing: 0
					}}
					View={VIEW}
					width={150}
					search={Dates}
					setPage={page => {
						setDates({
							...Dates,
							page,
							total:Dates.totalPages
						})
					}}
					data={{
						current: Data.currentPage,
						total: Data.totalPages,
						items,
						cards: Data.items.map(item => ({
							content: item,
							render: (
								<div onClick={()=>{
									HISTORY.push(`/app/apps/meta/transaction/${item.transId}`)
								}} className="retro-card">
									<p className="fw-bold">
										INR {item.amount}
									</p>
									<h3 className="fw-bold">
										{item.transId}
									</h3>
									<p>
										{item.client.name}
									</p>
									<p>
										{item.provider}
									</p>
									<Seperator/>
									<p>
										{Moment(item.createdAt).format("DD MMM YY HH:mm")}
									</p>
									<p>
										{item.status}
									</p>
								</div>
							)
						}))
					}} headers={[
					"Trans Id","Provider","Provider Id","Client Code","Client Name","Payment Type",
					"Amount", "Departure Date", "Departure Time", "Timezone", "Actions"
				].map((item)=>({
					weight: 2,
					title: item,
				}))}>

				</ScrollableDynamicTable>
			</div>
		</div>
	)
}
