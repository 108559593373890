import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Button, KeyValue, Modal, Pages, SelectInput, Seperator, TOAST} from "../../../../../retro";
import {get, post} from "../../../../Network/Axios";
import {useParams} from "react-router-dom";

export default function Status() {
	const {id} = useParams();
	const [Data, SetData] = useState({
		loading: true,
	})
	const REF = useRef();
	const [value,setValue] = useState("LIVE")
	const [modal,setModal] = useState(false)
	const load = useCallback(()=>{
		get(`/clients/${id}`, (e, r) => {
			if (r) {
				SetData({
					...r,
					loading: false
				})
			}
		})
	},[id])
	useEffect(()=>{
		load()
	},[])
	return (
		<div>
			{
				modal && <Modal title="Change status" description="Change the status of current client." onClose={()=>{setModal(false)}}>
					<SelectInput ref={REF} defaultValue={Data.status || value} onChange={setValue} options={[{value:"LIVE",label:"LIVE"},{value:"DEACTIVATE",label:"DEACTIVATE"},{value:"TERMINATED",label:"TERMINATED"}]}/>
					<Button onClick={()=>{
						post(`clients/${id}/status`,{status:value},(e,r)=>{
							if(e){
								TOAST.handleError(e)
							}else{
								TOAST.success("Status updated successfully!!")
								load()   //https://dice-antrepriz.atlassian.net/browse/CTOOO-458
								setModal(false)
							}
						})
					}} className="btn btn-black" margin="mt4">
						Change
					</Button>
				</Modal>
			}
            <Seperator margin={4}/>
            <Pages.Title title="Danger Zone" description="This section will enable/disable client"/>
			<div className="mt2">
				<KeyValue title="Current Status" value={Data.status}/>
			</div>
			{Data?.status?.toLowerCase() !=='terminated' && 
						<Button onClick={()=>{
							setModal(true)
						}} className="btn btn-black">
					Change Status
			 </Button>
			}
		</div>
	)
}
