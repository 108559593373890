import React, { useCallback, useEffect, useState } from 'react';
import {
  Loader,
  ScrollableDynamicTable,
  SearchBox,
  TOAST as Alert,
  Navigation,
  Button,
} from 'retro';
import { get } from 'App/Network/Axios';
import OpenIcon from '@atlaskit/icon/glyph/open';
import ViewRequest from './ContractedRequest';
import { DownloadCsv } from 'retro/Utils';
import moment from 'moment';
import RBAC from 'App/HOCs/RBAC';
import ROLES from 'App/Constants/Roles';
import FilterDropDown from 'App/Components/Filter/FilterDropDown';
import Filter from 'retro/Filter';

export default function ongoing() {
  let [loading, setLoading] = useState(true);
  const [id, setId] = useState(undefined);
  const [search, setSearch] = useState({
    page: 0,
    sort: 'id',
    order: 'desc',
  });
  const [Data, SetData] = useState({
    total: 0,
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const [filterModal,setFilterModal] = useState(false);
	const [filter, setFilter] = useState({
		client: [],
		checkin: []
	});
  // const loadPage = useCallback(
  //   (search) => {
  //     get(
  //       `/meta/contracted/history`,
  //       (e, r) => {
  //         if (r) {
  //           SetData({
  //             ...r,
  //             total: r.total ? r.total : Data.total,
  //             currentPage: r.currentPage,
  //             totalPages: r.totalPages ? r.totalPages : Data.totalPages,
  //           });
  //           setLoading(false);
  //         } else {
  //           Alert.handleError(e);
  //         }
  //       },
  //       {
  //         offset: search.page,
  //         order: search.order,
  //         q: search.q,
  //       }
  //     );
  //   },
  //   [Data, SetData]
  // );
  const loadPage = useCallback(
    (search) => {
		const clientIds = filter.client.map(client => client.id);

      get(
        `/meta/contracted/history`,
        (e, r) => {
          if (r) {
            SetData({
              ...r,
              total: r.total || Data.total,
              totalPages: r.totalPages || Data.totalPages,
            });
            setLoading(false);
          } else {
            Alert.handleError(e);
          }
        },
        {
			offset: search.page,
			order: search.order,
			q: search.q,
      
			start: filter.checkin.length > 0 ?
					filter.checkin[0] === 'Upcoming 24 hours' ?
						moment().valueOf().toString() : moment().add(24, 'hours').valueOf().toString()
					: undefined,
			end: filter.checkin.length > 0 ?
				filter.checkin[0] === 'Upcoming 24 hours' ? moment().add(24, 'hours').valueOf().toString() : moment().add(48, 'hours').valueOf().toString()
				: undefined,
			clientIds: clientIds.length > 0 ? clientIds : undefined
        }
      );
    },
    [Data, SetData, filter.checkin, filter.client]
  );

  
  useEffect(() => {
    loadPage(search);
  }, [search, filter.checkin, filter.client]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div className='mb2 relative'>
        <Navigation title='API History' />
        <RBAC role={ROLES.REPORTS}>
          <Button
            onClick={() => {
              setLoading(true);
              get(`/meta/contracted/history/all`, (e, r) => {
                if (r) {
                  let data = [];
                  const headers = [
                    'Request Id',  //https://dice-antrepriz.atlassian.net/browse/CTOOO-145
                    'City',
                    'Hotel Name',
                    'Hotel Address',
                    'Status',
                    'Client Code',
                    'Guest Name',
                    'Guest Email',
                    'Travel Date',
                    'Request Date',
                    "Confirmed Date",
                    'Decline Reason'
                  ];
                  data.push(headers);
                  r.items.forEach((item) => {
                    let line = [];
                    line.push([item?.referenceId||"-"]);  //https://dice-antrepriz.atlassian.net/browse/CTOOO-145
                    line.push([item?.hotel?.city || '-']);
                    line.push([item?.hotel?.name || '-']);
                    line.push([item?.hotel?.address || '-']);
                    line.push([item?.status||"-"]);
                    line.push([item?.client?.clientCode||"-"]);
                    line.push([item?.guests?.[0]?.name||"-"]);
                    line.push([item?.guests?.[0]?.email||"-"]);
                    line.push([
                      `${item?.searchRequest?.dates?.in||"-"} - ${item?.searchRequest?.dates?.out||"-"}`,
                    ]);
                    line.push([
                      item?.createdAt? moment(item?.createdAt).format("DD MMM YYYY hh:mm A"):"-",
                    ]);
                    line.push([
                      item?.updatedAt? moment(item?.updatedAt).format("DD MMM YYYY hh:mm A"):"-",
                    ]);
                    line.push([item?.failedReason ||"-"]);
                    data.push(line);
                  });
                  DownloadCsv(
                    `api_history${moment().format('DD_MM_YYYY')}}.csv`,
                    data
                  );
                }
                setLoading(false);
              });
            }}
            loading={loading}
            className='btn btn-primary btn-sm absolute top-2 right-0'
            style={{ zIndex: 10 }}
          >
            <i className='fa fa-download mr1' />
            Download Data
          </Button>
        </RBAC>
      </div>
      {/* <SearchBox
        onChange={(q) => {
          setSearch({
            ...search,
            page: 0,
            q,
          });
        }}
        placeholder='Enter your search criteria '
      /> */}
    <Filter
			margin="0 -4rem"
			onSearch={(q) => {
				setSearch({
					...search,
					page: 0,
					q,
				})
			}}
			onFilter={() => {
				setFilterModal(true)
			}} 
		/>

		<FilterDropDown
			shown={filterModal}
			filters={filter}
			setFilters={setFilter}
			choices={{
				client: [],
				checkin: [
					{
						title: 'Upcoming 24 hours',
						type: 'radio'
					},
					{
						title: 'Upcoming 48 hours',
						type: 'radio'
					}
				]
			}}
			types={["Client", "CheckIn"]}
			onClose={() => {
				setFilterModal(false);
			}}
		/>
      <ScrollableDynamicTable
        setSearch={(search) => {
          setSearch({
            ...search,
            page: 0,
          });
        }}
        search={search}
        tableStyle={{
          borderSpacing: 0,
        }}
        setPage={(page) => {
          setSearch({
            ...search,
            page,
          });
        }}
        data={{
          current: Data.currentPage,
          total: Data.totalPages,
          items: Data.items.map((item) => {
            const click = () => {
              setId(item.queueId);
            };
            return {
              content: item,
              render: [
                {
                  children: (
                    <div>
                      <p className='text'>{item.referenceId}</p>
                      <p className='text'>{item.hotel.name}</p>
                      <p className='text-small text-light'>
                        {item.hotel.address}
                      </p>
                    </div>
                  ),
                  weight: 2,
                },
                {
                  weight: 1,
                  title: item.status,
                },
                {
                  weight: 1,
                  title: item.hotel.city,
                },
                {
                  weight: 1,
                  title: item.client.clientCode,
                },
                {
                  weight: 1,
                  buttons: [
                    {
                      title: 'View',
                      className: 'btn-secondary btn-sm',
                      before: <OpenIcon size='small' />,
                      onClick: click,
                    },
                  ],
                  actions: [
                    {
                      title: 'Quick Actions',
                      actions: [
                        {
                          title: 'View',
                          before: <OpenIcon size='small' />,
                          onClick: click,
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          }),
        }}
        headers={[
          {
            weight: 2,
            title: 'Details',
          },
          {
            weight: 1,
            title: 'Status',
          },
          {
            weight: 1,
            title: 'City',
          },
          {
            weight: 1,
            title: 'Client',
          },
          {
            weight: 1,
            title: 'Actions',
            style: {
              justifyContent: 'end',
            },
          },
        ]}
      />
      {id !== undefined && (
        <ViewRequest
          id={id}
          onClose={() => {
            setId(undefined);
            loadPage(search);
          }}
        />
      )}
    </div>
  );
}
