import React, {useCallback, useRef, useState} from 'react'
import {Button, InlineTable, Input, Modal, SidePane, Switch, TOAST} from "../../../../../retro";
import {post} from "../../../../Network/Axios";
import SectionMessage from "@atlaskit/section-message";

export default function SidePaneIndex({onClose,config,modal,setConfig,id,reload}) {
    const KEY = useRef();
    const VALUE = useRef();
    const [key, setKey] = useState('');
    const [markupValue, setMarkupValue] = useState(0);

    const [airline,setAirline] = useState(false)
    const [tagModal,setTagModal] = useState(false)

    const markupToggles = ['CL_V3_RESELLER', 'CL_V3_AGENCY_ONLY', 'CL_V3_PURE_AGENCY', 'CL_V3_RESELLER_OFFLINE', 'CL_V3_AGENCY_TO_RESELLER'];

    const section = () => {
        if(modal==="CL_FLIGHT" || modal === "CL_HOTEL"){
            return (
                <div style={{marginTop:20}}>
                    <SectionMessage title="For Custom Keys:-">
                        <p>
                           <b>X-CT-API-KEY</b> for Api Key.
                        </p>
                        <p>
                            <b>
                                X-CT-DEPOSIT
                            </b> for deposit account.
                        </p>
                    </SectionMessage>
                </div>
            )
        }
    }
    const toggles = useCallback(() => {
        if(modal==="TJ_FLIGHT"){
            const airline = config[modal]?.config?.airlines||"[]"
            const array = JSON.parse(airline);
            return(
                <div>
                    <div className="border-sm pd4 mt4">
                        <p className="fw-bold">
                            Airlines:-
                        </p>
                        <InlineTable  headers={["Airline"]} rows={
                            array.map(mAirline=> {
                                return [mAirline]

                            })
                        }/>
                        <p onClick={()=>{
                            setAirline(true)
                        }} className="fw-bold mt4 pointer">
                            + Add Airline
                        </p>
                    </div>

                </div>
            )
        }
    },[config])
	return (
        <SidePane onClose={onClose} title="Manage Keys" description="Update details keys">
            {section()}
            <div className="border-sm pd4 mt4">
                <p className="fw-bold mb2">
                    Manage Keys
                </p>
                <InlineTable  headers={["Key","Value","Actions"]} rows={
                    Object.keys(config[modal]?config[modal].config:{}).map(value =>{
                        return [
                            value,
                            <div className="text-truncate" style={{ maxWidth: '100px', display: 'inline-block' }}>
                                {config[modal].config[value]}
                            </div>
                            ,(
                                <Button onClick={()=>{
                                    let data = {...config};
                                    let mConfig = data[modal].config;
                                    delete mConfig[value];
                                    data[modal].config = mConfig;
                                    setConfig(data)
                                }} margin="mt1 mb1" className="btn-link btn-sm btn-link-danger">
                                    - Delete
                                </Button>
                            )
                        ]
                    })
                }/>
                <Button onClick={()=>{setTagModal(true)}} className="btn-link">
                    + Add Keys
                </Button>
            </div>
            {toggles()}
            <Button onClick={()=>{
                let data = {...config}[modal].config;
                post(`/clients/apis/${id}/config`,{type:modal,data},(e,r)=>{
                    if(r){
                        onClose()
                        reload()
                    }
                })
            }} className="mt4 btn-black">
                Upload Keys
            </Button>
            {
                tagModal &&
                <Modal onClose={()=>{
                    setKey('');
                    setMarkupValue(0);
                    setTagModal(false)
                }} button="Add Tags" onSubmit={()=>{
                    let key = KEY.current.value;
                    let value = VALUE.current.value;
                    if(!key){
                        TOAST.error("Enter a valid key")
                        return
                    }

                    if (markupToggles.includes(modal)) {
                        if (key.toLowerCase() === 'markup' && key !== 'markup') {
                            return TOAST.error('Markup key must be in lowercase');
                        }
                    }

                    if(!value){
                        TOAST.error("Enter a valid value")
                        return
                    }

                    if (markupToggles.includes(modal) && key === 'markup') {
                        const markupValue = Number(value);
                        if (!markupValue) {
                            return TOAST.error('Invalid markup value');
                        }
                        if (markupValue < -10 || markupValue > 25) {
                            return TOAST.error('Markup value should be between -10 and 25');
                        }
                        value = markupValue.toFixed(2);
                    }

                    let mConfig = config[modal].config;
                    mConfig[key] = value;
                    config[modal].config = mConfig;
                    setConfig(config)
                    setKey('');
                    setMarkupValue(0);
                    setTagModal(false);
                }} title="Add Tags" description="Enter details to add a new tags" >
                    <Input
                        type="text"
                        ref={KEY}
                        onChange={markupToggles.includes(modal) ? val => setKey(val) : undefined}
                        label="Key"
                        placeholder="Enter key details"
                        morph={true}
                    />
                    <Input
                        type="text"
                        ref={VALUE}
                        onChange={markupToggles.includes(modal) ? val => setMarkupValue(val) : undefined}
                        label={markupToggles.includes(modal) ? 'Value (upto 2 decimal places)' : 'Value'}
                        placeholder="Enter key details"
                        morph={true}
                    />
                    {markupToggles.includes(modal) && key.toLowerCase() === 'markup' && (
                        <div style={{ marginTop: 12 }}>
                            <p style={{ fontWeight: 600 }}>Effective markup:</p>
                            <p style={{ fontSize: 12, marginTop: 4 }}>for 12% applicable GST = {Number(markupValue) ? (Number(markupValue).toFixed(2) * 1.12).toFixed(2) : '-'}%</p>
                            <p style={{ fontSize: 12 }}>for 18% applicable GST = {Number(markupValue) ? (Number(markupValue).toFixed(2) * 1.18).toFixed(2) : '-'}%</p>
                        </div>
                    )}
                </Modal>
            }
            {
                airline &&
                <Modal onClose={()=>{
                    setAirline(false)
                }} button="Add Airline" onSubmit={()=>{
                    let mAirline = KEY.current.value;
                    const airline = config[modal]?.config?.airlines||"[]"
                    const array = JSON.parse(airline);
                    array.push(mAirline);
                    let mConfig = config[modal].config;
                    mConfig["airlines"] = JSON.stringify(array);
                    config[modal].config = mConfig;
                    setConfig(config)
                    setAirline(false);
                }} title="Add Airline" description="Enter airline to add" >
                    <Input type="text" ref={KEY} label="Key" placeholder="Airline" morph={true}/>
                </Modal>
            }
        </SidePane>
	)
}
