// CTOOO-94 - Flight DSR report
import { useEffect, useRef, useState } from "react";
import moment from "moment";

import { Button, DateRange, Loader, Navigation, TOAST } from "retro";
import RBAC from "App/HOCs/RBAC";
import ROLES from "App/Constants/Roles";

import { get } from "App/Network/Axios";
import { DownloadCsv } from "retro/Utils";

import Graph from "./graph-stats-circle.svg";

export default function FlightDSR() {
  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState({
    start: moment().add(-31, "d").valueOf(),
    end: moment().valueOf(),
  });
  const startDateRef = useRef();
  const endDateRef = useRef();

  useEffect(() => {
    let templist = [];
    get(
      "/clients/dump",
      (_, r) => {
        if (r) {
          r.items.map((client) => {
            templist.push(client.code);
          });
          setClientList([...templist]);
        }
      },
      {
        active: true,
      },
    );
  }, []);

  const downloadData = () => {
    setLoading(true);
    get(
      `/meta/flight/agency`,
      (error, response) => {
        if (error) {
          setLoading(false);
          return TOAST.handleError(error);
        }

        if (response.bookings.length === 0) {
          setLoading(false);
          return TOAST.warning("No data found");
        }

        const headers = getTableData({}).map((rowData) => rowData.header);

        const values = response.bookings
          .filter((b) => b.ticket)
          .map((metaData) => {
            if (metaData.category === "DOMESTICROUNDFLIGHT") {
              // For domestic flight mapping over onwards and return segments
              return [metaData.ticket?.onwards, metaData.ticket?.returns]
                .filter(Boolean)
                .map(
                  (sectors, sectorIndex) =>
                    // Map travellers for each sector
                    metaData.ticket.travellers
                      .map((traveller, index) =>
                        sectors.map((segment, segmentIndex) =>
                          // Map over segment for onwards/return for each traveller
                          getTableData({
                            metaData,
                            traveller,
                            segment,
                            segmentIndex,
                            fareType:
                              index === 0
                                ? metaData.ticket?.fareType || "-"
                                : metaData.ticket?.returnFareType || "-",
                            travellerIndex: index,
                            isReturnFlight: sectorIndex === 1,
                          }).map((rowData) => rowData.value),
                        ),
                      )
                      .flat(1), // Flatten nested arrays of travellers into single array [[traveller1], [traveller2]] -> [traveller1, traveller2]
                )
                .flat(1); // Flatten nested arrays of sectors into single array [[sector1], [sector2]] -> [sector1, sector2]
            } else {
              // Map over segments for each traveller
              return metaData.ticket.travellers
                .map((traveller, index) =>
                  metaData.ticket.segments.map((segment, segmentIndex) =>
                    getTableData({
                      metaData,
                      traveller,
                      segment,
                      fareType: metaData.ticket?.fareType || "-",
                      segmentIndex,
                      travellerIndex: index,
                    }).map((rowData) => rowData.value),
                  ),
                )
                .flat(1); // Flatten nested arrays of travellers into single array [[segment1], [segment2]] -> [segment1, segment2]
            }
          })
          .flat(1); // Flatten nested arrays of travellers into single array [[traveller1], [traveller2]] -> [traveller1, traveller2]

        const csvData = [headers, ...values];

        DownloadCsv(
          `flight_dsr_${moment(startDateRef.current.value).format(
            "DD_MM_YYYY",
          )}_${moment(endDateRef.current.value).format("DD_MM_YYYY")}.csv`,
          csvData,
        );

        setLoading(false);
      },
      {
        start: moment(startDateRef.current.value).valueOf(),
        end: moment(endDateRef.current.value).add(1, "d").valueOf(),
      },
    );
  };
  return (
    <div style={{ width: "100%" }}>
      <Navigation
        backUrl={"back"}
        title="Flight DSR"
        chips={["Meta", "Flight", "DSR"]}
        description="Flight DSR appears below."
      />
      <div
        style={{
          margin: "0 -4rem",
          padding: "1rem 4rem",
          borderBottom: "1px solid #dedede",
        }}
      >
        <div className="flex horizontally center-vertically">
          <DateRange
            label="From Date"
            className="flex-1 ml1 mr1 mt1"
            ref={startDateRef}
            defaultValue={dates.start}
            onChange={() => {
              setDates({
                ...dates,
                start: startDateRef.current.value,
              });
            }}
          />
          <DateRange
            label="To Date"
            ref={endDateRef}
            defaultValue={dates.end}
            className="flex-1 mr1 ml1 mt1"
            onChange={() => {
              setDates({
                ...dates,
                end: endDateRef.current.value,
              });
            }}
          />
        </div>
      </div>
      {loading ? (
        <div className="pd6">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="border pd6 mt4 flex vertically center">
            <img
              alt="fetch"
              src={Graph}
              style={{ width: "5rem", height: "5rem" }}
            />
            <div className="flex mt4">
              <RBAC role={ROLES.REPORTS}>
                <Button onClick={downloadData} className="btn btn-black ">
                  Download Data
                </Button>
              </RBAC>
            </div>
            <p className="mt2">Download flight DSR data</p>
          </div>
        </div>
      )}
    </div>
  );
}

const getTableData = ({
  metaData = {},
  traveller = {},
  fareType = "",
  travellerIndex = 0,
  isReturnFlight = false,
  segment = {},
  segmentIndex = 0,
}) => {
  const isFirstLineItem =
    travellerIndex === 0 && !isReturnFlight && segmentIndex === 0;

  const getCountry = (flightFor) => {
    const sector = getSector();

    if (sector === "-") {
      return "-";
    }

    const [departureCode, arrivalCode] = sector.split("-");
    const code = flightFor === "arrival" ? arrivalCode : departureCode;

    const segment = metaData?.ticket?.segments?.find(
      (s) => s[flightFor].code === code,
    );

    return segment[flightFor]?.country || "-";
  };

  const getSector = () => {
    if (!segment.departure?.code || !segment.arrival?.code) {
      return "-";
    }

    return `${segment.departure?.code}-${segment.arrival?.code}`;
  };

  const getPNRs = () => {
    const sector = getSector();

    return traveller.pnrs?.[sector] || "-";
  };

  const getTickets = () => {
    const sector = getSector();

    return traveller.tickets?.[sector] || "-";
  };

  const getRefundRequestedAt = () => {
    // NOTE: For imported flights
    if (
      ["TJ_FLIGHT", "CL_IMPORT", "CL_IMPORT_INT"].includes(metaData.provider)
    ) {
      if (isFirstLineItem) {
        return metaData.invoiceConfig?.cancelledAt
          ? moment(metaData.invoiceConfig.cancelledAt).format(
              "DD MMM YYYY hh:mm a",
            )
          : "-";
      }
      return "-";
    }

    const currentSector = (traveller.allSequences || []).find(
      (seq) => seq.sector === getSector(),
    );
    if (
      currentSector?.status === "REFUNDING" ||
      currentSector?.status === "REFUNDED"
    ) {
      return currentSector.refundRequestedAt
        ? moment(currentSector.refundRequestedAt).format("DD MMM YYYY hh:mm a")
        : "-";
    }

    return "-";
  };

  const getAirlineCancellationFees = () => {
    // NOTE: For imported flights
    if (
      ["TJ_FLIGHT", "CL_IMPORT", "CL_IMPORT_INT"].includes(metaData.provider)
    ) {
      if (isFirstLineItem) {
        return (
          metaData.amount -
            metaData.refundAmount -
            metaData.invoiceConfig?.serviceCharges -
            metaData.invoiceConfig?.platformCancellationCharges || "-"
        );
      }
      return "-";
    }

    const currentSector = (traveller.allSequences || []).find(
      (seq) => seq.sector === getSector(),
    );
    if (
      currentSector?.status === "REFUNDING" ||
      currentSector?.status === "REFUNDED"
    ) {
      return currentSector.airlineCharges ?? "-";
    }

    return "-";
  };

  const getPlatformCharges = () => {
    // NOTE: For imported flights
    if (
      ["TJ_FLIGHT", "CL_IMPORT", "CL_IMPORT_INT"].includes(metaData.provider)
    ) {
      if (isFirstLineItem) {
        return metaData.invoiceConfig?.platformCancellationCharges ?? "-";
      }

      return "-";
    }

    const currentSector = (traveller.allSequences || []).find(
      (seq) => seq.sector === getSector(),
    );
    if (
      currentSector?.status === "REFUNDING" ||
      currentSector?.status === "REFUNDED"
    ) {
      return currentSector.platformCharges ?? "-";
    }

    return "-";
  };

  const getRefundAmount = () => {
    // NOTE: For imported flights
    if (
      ["TJ_FLIGHT", "CL_IMPORT", "CL_IMPORT_INT"].includes(metaData.provider)
    ) {
      if (isFirstLineItem) {
        return metaData.refundAmount ?? "-";
      }

      return "-";
    }

    const currentSector = (traveller.allSequences || []).find(
      (seq) => seq.sector === getSector(),
    );
    if (
      currentSector?.status === "REFUNDING" ||
      currentSector?.status === "REFUNDED"
    ) {
      return currentSector.refundAmount ?? "-";
    }

    return "-";
  };

  return [
    {
      header: "Id",
      value: metaData.id || "-",
    },
    {
      header: "Provider",
      value: metaData.provider || "-",
    },
    {
      header: "Category",
      value:
        metaData.category === "DOMESTICROUNDFLIGHT" ? "Round Trip" : "One Way",
    },
    {
      header: "Booking Id",
      value: metaData.transId || "-",
    },
    {
      header: "Status",
      value: metaData.status || "-",
    },
    {
      header: "Provider Id",
      value: metaData.providerId || metaData.ticket?.flowId || "-",
    },
    {
      header: "Payment Type",
      value: metaData.paymentType || "-",
    },
    {
      header: "Amount",
      value: isFirstLineItem ? metaData.amount : "-",
    },
    {
      header: "Voucher",
      value: metaData.invoiceConfig?.file || "-",
    },
    {
      header: "GSTIN",
      value: metaData.invoiceConfig?.userGst?.gstin || "-",
    },
    {
      header: "Client Code",
      value: metaData.client?.code || "-",
    },
    {
      header: "Client Name",
      value: metaData.client?.name || "-",
    },
    {
      header: "Traveller Name",
      value: traveller.name,
    },
    {
      header: "Domestic/International",
      value: metaData.invoiceConfig?.isInternational
        ? "International"
        : "Domestic",
    },
    {
      header: "Journey Time",
      value: segment.departureTime
        ? moment(segment.departureTime).format("DD MMM YYYY hh:mm a")
        : "-",
    },
    {
      header: "Created On Date",
      value: metaData.createdAt
        ? moment(metaData.createdAt).format("DD MMM YYYY")
        : "-",
    },
    {
      header: "Created On Time",
      value: metaData.createdAt
        ? moment(metaData.createdAt).format("hh:mm a")
        : "-",
    },
    {
      header: "Updated On Date",
      value: metaData.updatedAt
        ? moment(metaData.updatedAt).format("DD MMM YYYY")
        : "-",
    },
    {
      header: "Updated On Time",
      value: metaData.updatedAt
        ? moment(metaData.updatedAt).format("hh:mm a")
        : "-",
    },
    {
      header: "Cancelled At",
      value:
        (metaData.status === "REFUNDED" || metaData.status === "PARTIAL") &&
        metaData.invoiceConfig?.cancelledAt
          ? moment(metaData.invoiceConfig.cancelledAt).format(
              "DD MMM YYYY hh:mm a",
            )
          : "-",
    },
    {
      header: "Refund Requested At",
      value:
        metaData.status === "REFUNDED" || metaData.status === "PARTIAL"
          ? getRefundRequestedAt()
          : "-",
    },
    {
      header: "Platform Cancellation Charges",
      value:
        metaData.status === "REFUNDED" || metaData.status === "PARTIAL"
          ? getPlatformCharges()
          : "-",
    },
    {
      header: "Airline Cancellation Charges",
      value:
        metaData.status === "REFUNDED" || metaData.status === "PARTIAL"
          ? getAirlineCancellationFees()
          : "-",
    },
    {
      header: "Refund Amount",
      value:
        metaData.status === "REFUNDED" || metaData.status === "PARTIAL"
          ? getRefundAmount()
          : "-",
    },
    {
      header: "Service Charge Base Fare",
      value: isFirstLineItem
        ? metaData.invoiceConfig?.serviceFare?.base || "-"
        : "-",
    },
    {
      header: "Service Charge CGST",
      value: isFirstLineItem
        ? metaData.invoiceConfig?.serviceFare?.cGst || "-"
        : "-",
    },
    {
      header: "Service Charge SGST",
      value: isFirstLineItem
        ? metaData.invoiceConfig?.serviceFare?.sGst || "-"
        : "-",
    },
    {
      header: "Service Charge IGST",
      value: isFirstLineItem
        ? metaData.invoiceConfig?.serviceFare?.iGst || "-"
        : "-",
    },
    {
      header: "Service Charge Slab",
      value: isFirstLineItem
        ? metaData.invoiceConfig?.serviceFare?.slab || "-"
        : "-",
    },
    {
      header: "Service Charge",
      value: isFirstLineItem
        ? metaData.invoiceConfig?.serviceCharges || "-"
        : "-",
    },
    {
      header: "Service Charge Invoice",
      value: metaData.invoiceConfig?.serviceInvoice || "-",
    },
    {
      header: "Discount",
      value: metaData.invoiceConfig?.discount ?? "-",
    },
    {
      header: "Sector",
      value: getSector(),
    },
    {
      header: "Operating airline",
      value: segment.operatingAirline || "-",
    },
    {
      header: "Airline name",
      value: segment.airline || "-",
    },
    {
      header: "Airline code",
      value: segment.flightCode || "-",
    },
    {
      header: "Flight number",
      value: segment.flightNumber || "-",
    },
    {
      header: "Fare Type",
      value: fareType,
    },
    {
      header: "Cabin Class",
      value: metaData.ticket?.cabinClass || "-",
    },
    {
      header: "PNRs",
      value: getPNRs(),
    },
    {
      header: "Tickets",
      value: getTickets(),
    },
    {
      header: "Origin country",
      value: getCountry("departure"),
    },
    {
      header: "Destination country",
      value: getCountry("arrival"),
    },
  ];
};
