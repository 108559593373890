/**
 * Author[Shubham Rawat]
 * Version 1.0.0
 * 11th December | 1.0.0 | Shubham Rawat | created the import form for train
 */
import React, { useEffect, useRef, useState } from 'react';
import {
  Attachment,
  Button,
  DateRange,
  FileInput,
  Input,
  Modal,
  NavDivider,
  Navigation,
  SelectInput,
  TOAST,
} from 'retro/index';
import Moment from 'moment/moment';
import { get, post } from 'App/Network/Axios';
import { PrefferedGST } from '../ImportHotel';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ImportTrain = () => {
  const [formData, setFormData] = useState({
    prefferedGst: undefined,
    prefferedGstName: undefined,
    clientCode: undefined,
    clientId: undefined,
    city: undefined,
    email: undefined,
    allGsts: [],
  });

  const HISTORY=useHistory();

  const EMAIL = useRef();
  const GSTIN = useRef();
  const ACCOUNT = useRef();
  const AMOUNT = useRef();
  const PG = useRef();
  const TRAINNAME = useRef();
  const TRAINDATE = useRef();
  const TRAINNUMBER = useRef();
  const TRAINSEAT = useRef();
  const PNR = useRef();
  const ORIGIN = useRef();
  const ORIGINCITY = useRef();
  const DESTINATION = useRef();
  const DESTINATIONCITY = useRef();
  const TYPE = useRef();
  const FILE = useRef();
  const BOOKING_TYPE=useRef();
  const TRAIN_TO_DATE=useRef();
  const TRIPID = useRef();

  const [accounts, setAccounts] = useState([]);
  const [accountType, setAccountType] = useState(undefined);
  const [travellers, setTravellers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [totalAmount,setTotalAmount] = useState(undefined)
  const [serviceCharges,setServiceCharges] = useState({
    "GENERAL": 0,
    "AC": 0,
    "TATKAL":0
  })
  const [bookingType,setBookingType] = useState("GENERAL");

  // set dropdowns as per the cleint
  useEffect(() => {
    setAccounts([]);
    if (formData.clientCode) {
      // get the list of accounts for the client
      get(`/accounts/${formData.clientId}`, (e, r) => {
        if (r) {
          setAccounts(r.items);
          setAccountType(undefined);
        }
      });
      get(`/clients/apis/${formData.clientId}`,(e,r)=>{
        if(r){
          setServiceCharges({
            "GENERAL":r.travelConfig?.train || 0,
            "AC":r.travelConfig?.trainAc || 0,
            "TATKAL":r.travelConfig?.trainTatkal || 0
          })
        }
      })
    }
  }, [formData.clientCode]);
  
  useEffect(()=>{
      let numberOfTravllers = travellers.length;
      let serviceCharge = Number(serviceCharges[bookingType]);
      let amount = Number(price) || 0
      let total = amount + (serviceCharge*numberOfTravllers)
      setTotalAmount(total)
  },[price,travellers,bookingType])

  //   function to add booking import
  const importBooking = () => {
    let email = EMAIL.current.value;
    // let amount = AMOUNT?.current?.value;
    let account = ACCOUNT.current.value;
    let gstin = GSTIN.current.value;
    let pgId = PG?.current?.value;
    let origin = ORIGIN.current.value;
    let originCity = ORIGINCITY.current.value;
    let destination = DESTINATION.current.value;
    let destinationCity = DESTINATIONCITY.current.value;
    let trainDate = TRAINDATE.current.value;
    let toDate=TRAIN_TO_DATE.current.value;
    let trainName = TRAINNAME.current.value;
    let trainNumber = TRAINNUMBER.current.value;
    let trainSeat = TRAINSEAT.current.value;
    let trainPnr = PNR.current.value;
    let type = TYPE.current.value;
    let quotaType=BOOKING_TYPE?.current?.value;
    if(!quotaType){
      TOAST.error('Select a booking type');
      return;
    }
    if (!email) {
      TOAST.error('Enter a valid amount');
      return;
    }
    if (!Number(price) || Number(price) < 100) {
      TOAST.error('Enter a valid amount');
      return;
    }
    if (!gstin) {
      TOAST.error('Select a GSTIN');
      return;
    }
    if (!account) {
      TOAST.error('Select an account');
      return;
    }
    if (!origin) {
      TOAST.error('Enter origin');
      return;
    }
    if (!destination) {
      TOAST.error('Enter destination');
      return;
    }
    if (!trainName) {
      TOAST.error('Enter a train name');
      return;
    }
    if (!trainNumber) {
      TOAST.error('Enter a train number');
      return;
    }
    if (!trainSeat) {
      TOAST.error('Enter a train seat number');
      return;
    }
    if (!trainPnr) {
      TOAST.error('Enter a train pnr');
      return;
    }
    if (!type) {
      TOAST.error('Select a type');
      return;
    }

    if (!file) {
      TOAST.error('Please add a file');
      return;
    }

    setLoading(true);
    trainDate = Moment(trainDate).format('DD-MM-YYYY');
    toDate = Moment(toDate).format('DD-MM-YYYY');

    let data = {
      provider_id: `import_train_(${Moment().unix()})`,
      client: formData.clientCode,
      email,
      date: trainDate,
      amount:Number(price),
      gstin,
      account:accountType,
      pgId,
      toDate,
      tripId:TRIPID?.current?.value,
      ticket: {
        origin,
        destination,
        trainNumber,
        trainName,
        date: trainDate,
        toDate,
        originCity,
        destinationCity,
        trainPnr,
        trainSeat,
        amount:Number(price),
        travellers: travellers,
        file: file,
        type:quotaType,
        merchant:type,
      },
    };
    post(`/meta/train/import`, data, (e, r) => {
      setLoading(false); 
      if (e) {
        TOAST.handleError(e);
      } else {
        if(r?.refId){
          HISTORY.push(`/app/apps/meta/transaction/${r.refId}`)
        }
        TOAST.success('Booking imported successfully');
        EMAIL.current.value = '';
        setPrice(0);

      }
    });
  };

  return (
    <div>
      <Navigation backUrl={'back'} title="Import Train Booking" />
      <NavDivider />
      {!formData.prefferedGst ? (
        <PrefferedGST data={formData} setData={setFormData} category="train" />
      ) : (
        <>
          <div className="flex horizontally center-vertically mt1">
            <SelectInput
              placeholder="Select"
              hidePlaceholder={false}
              options={[
                ...accounts.map((item) => {
                  return {
                    label: `${item.title} (INR ${item.balance})`,
                    value: item.accountId,
                  };
                }),
                { label: 'RAZORPAY', value: 'RAZORPAY' },
              ]}
              onChange={setAccountType}
              ref={ACCOUNT}
              label="Select Deposit Account"
              className="mr1 flex-1"
            />
            <SelectInput
              placeholder="Select"
              hidePlaceholder={false}
              options={[
                {
                  label: `${formData.prefferedGst} - ${formData.prefferedGstName} (Preffered GST)`,
                  value: formData.prefferedGst,
                },
                ...formData.allGsts.map((gst) => {
                  return {
                    label: `${gst.gstin} - ${gst.name}`,
                    value: gst.gstin,
                  };
                }),
              ]}
              defaultValue={formData.prefferedGst}
              ref={GSTIN}
              label="Select the GSTIN"
              className="flex-1"
            />
          </div>
          {/* https://dice-antrepriz.atlassian.net/browse/CTOOO-71 */}
          <div className="flex horizontally center-vertically mt2">
            <Input 
            type='text' 
            ref={TRIPID} 
            label="Enter Trip Id (Optional)"
            placeholder="Enter trip id"
            className="flex-1"
            />
          </div>
          {accountType === 'RAZORPAY' && (
            <Input
              ref={PG}
              type="text"
              label="Enter Pg Id"
              placeholder="Enter Pg Id"
              className="mb2"
            />
          )}
          <div className="flex horizontally center-vertically mt1">
            <Input
              ref={EMAIL}
              type="text"
              label="Requestor Email"
              placeholder="Email Id"
              className="mr1 flex-1"
              defaultValue={formData.email}
            />
            <Input
              ref={TRAINSEAT}
              type="text"
              label="Train Seat Number"
              placeholder="Seat Number"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              ref={TRAINNAME}
              type="text"
              label="Train Name"
              placeholder="Train Name"
              className="mr1 flex-1"
            />
            <Input
              ref={TRAINNUMBER}
              type="text"
              label="Train Number"
              placeholder="Train Number"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              ref={ORIGIN}
              type="text"
              label="Origin"
              placeholder="Origin"
              className="mr1 flex-1"
            />
            <Input
              ref={DESTINATION}
              type="text"
              label="Destination"
              placeholder="Destination"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              ref={ORIGINCITY}
              type="text"
              label="Origin City"
              placeholder="Origin City"
              className="mr1 flex-1"
            />
            <Input
              ref={DESTINATIONCITY}
              type="text"
              label="Destination City"
              placeholder="Destination City"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <DateRange
              ref={TRAINDATE}
              // type="date"
              label="Travel Start Date"
              placeholder="Train Date"
              className="mr1 flex-1"
            />
             <DateRange
              ref={TRAIN_TO_DATE}
              // type="date"
              label="Travel End Date"
              placeholder="Train Date"
              className="ml1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              ref={PNR}
              type="text"
              label="PNR"
              placeholder="PNR"
              className="mr1 flex-1"
            /> 
            <SelectInput
              options={[
                { label: 'CONTRACTED', value: 'CONTRACTED' },
                { label: 'Trip Jack', value: 'Trip Jack' },
                { label: 'Cleartrip', value: 'Cleartrip' },
                { label: 'Others', value: 'Others' },
                { label: 'Birdres', value: 'Birdres' },
                { label: 'Aadhyaa Travels', value: 'Aadhyaa Travels' },
              ]}
              ref={TYPE}
              type="number"
              label="Type"
              hidePlaceholder={true}
              className="ml1 mb1 flex-1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
          <SelectInput 
            options={[{label:"GENERAL",value:"GENERAL"},{label:"AC",value:"AC"},{label:"TATKAL",value:"TATKAL"}]} ref={BOOKING_TYPE}  label="Booking Type" 
            hidePlaceholder={true} 
            onChange={setBookingType}
            className="mr1 mb1 flex-1"
            />
          <div className='flex-1 ml1 mt1'>
          <label>Amount
             <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value || "")}
              placeholder="Amount"
            />
            </label>
            </div>
          </div>

          {/* modal to add multiple travellers */}
          <AddTravellerModal
            travellers={travellers}
            setTravellers={setTravellers}
          />
          {/* ticket attachment */}
          <div className="mt2">
            <FileInput
              label="Attach the copy of the ticket"
              updateFiles={(url) => {
                setFile(url);
              }}
              ref={FILE}
            />
            <Attachment url={file} onDelete={() => setFile(undefined)} />
          </div>
          <div className="flex horizontally center-vertically mt4 ">
            <Button
              onClick={() => setFormData({})}
              className="btn-black btn-primary mr4"
              margin="pd2"
            >
              <i className="fa-solid fa-arrow-left mr1" />
              Previous
            </Button>
            <Button
              onClick={importBooking}
              className="btn-black btn-primary"
              margin="pd2"
              loading={loading}
            >
              Confirm Booking (INR {totalAmount || 0})
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

// component to add travellers
export const AddTravellerModal = ({ travellers, setTravellers }) => {
  const [showModal, setShowModal] = useState(false);

  const TNAME = useRef();
  const TEMAIL = useRef();
  const TTYPE = useRef();
  const TMOBILE = useRef();
  const TGENDER = useRef();
  const TSEAT = useRef();

  const addTraveller = () => {
    if (!TNAME.current.value) {
      TOAST.error('Enter valid name');
      return;
    }
    if (!TEMAIL.current.value) {
      TOAST.error('Enter valid email');
      return;
    }
    if (!TSEAT.current.value) {
      TOAST.error('Enter valid seat number');
      return;
    }
    setTravellers([
      ...travellers,
      {
        name: TNAME.current.value,
        email: TEMAIL.current.value,
        mobile: TMOBILE.current.value,
        type: TTYPE.current.value,
        seatNo: TSEAT.current.value,
        gender: TGENDER.current.value,
      },
    ]);
    setShowModal(false);
  };

  const removeTraveller = (email) => {
    let tempTravellers = travellers.filter(
      (traveller) => traveller.email !== email
    );
    setTravellers([...tempTravellers]);
  };

  return (
    <>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          title="Add Traveller Details"
          width={675}
        >
          <div className="flex horizontally center-vertically mt1">
            <Input
              morph={true}
              label="Name"
              placeholder="Traveller Name"
              ref={TNAME}
              className="flex-1 mr1"
            />
            <Input
              morph={true}
              label="Type"
              placeholder="Traveller Type"
              ref={TTYPE}
              className="flex-1 ml1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              morph={true}
              label="Email"
              placeholder="Traveller Email"
              ref={TEMAIL}
              className="flex-1 mr1"
            />
            <Input
              morph={true}
              label="Mobile"
              placeholder="Traveller Mobile"
              ref={TMOBILE}
              className="flex-1 ml1"
            />
          </div>
          <div className="flex horizontally center-vertically mt1">
            <Input
              morph={true}
              label="Seat Number"
              placeholder="Traveller Seat Number"
              ref={TSEAT}
              className="flex-1 mr1"
            />
            <SelectInput
              options={[
                { label: 'MALE', value: 'MALE' },
                { label: 'FEMALE', value: 'FEMALE' },
              ]}
              ref={TGENDER}
              type="number"
              label="Gender"
              hidePlaceholder={true}
              className="flex-1 ml1"
            />
          </div>
          <Button className="btn btn-primary" onClick={addTraveller}>
            <i className="fas fa-plus mr1"></i>
            Add Traveller
          </Button>
        </Modal>
      )}
      <div className="pd2 border mt2">
        {travellers.map((traveller) => {
          return (
            <div
              key={traveller.email}
              className="flex justify-between pd1 border-bottom"
            >
              <span style={{ width: '20%' }}>{traveller.name}</span>
              <span style={{ width: '15%' }}>{traveller.type}</span>
              <span style={{ width: '25%' }}>{traveller.email}</span>
              <span style={{ width: '20%' }}>{traveller.mobile}</span>
              <span style={{ width: '5%' }}>{traveller.seatNo}</span>
              <span style={{ width: '10%' }}>{traveller.gender}</span>
              <i
                className="fas fa-trash pointer"
                style={{ width: '3%', color: 'red' }}
                onClick={() => removeTraveller(traveller.email)}
              />
            </div>
          );
        })}

        <Button
          className="btn btn-sm btn-primary"
          onClick={() => setShowModal(true)}
        >
          <i className="fas fa-plus mr1"></i>
          Add Traveller
        </Button>
      </div>
    </>
  );
};

export default ImportTrain;
